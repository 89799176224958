import { ConfigProvider } from "antd";
import Router from "./router/Router";

const App = () => {
  return (
    <ConfigProvider direction="ltr">
      <Router />
    </ConfigProvider>
  );
};

export default App;
