import { Graph } from "react-iconly";
import { FcSurvey,GrList } from "react-icons/fc";
import { BsListUl,BsListTask } from "react-icons/bs";

const pages = [
  // {
  //   header: "PAGES",
  // },
  {
    id: "Home",
    icon: <Graph set="curved" className="remix-icon" />,
    title: "Home",
    navLink: "/",
    role:'All',
  },

  {
    id: "PatientList",
    icon: <FcSurvey set="curved" className="remix-icon" />,
    title: "Patient List",
    navLink: "/subjectlist",
    role: 'All',
  },
  {
    id: "Follow Up Subject",
    icon: <BsListUl set="curved" className="remix-icon" />,
    title: "Follow Up Subject",
    navLink: "/FollowUpSubject",
    role: 'All',
  },
  {
    id: "UserManagement",
    icon: <BsListUl set="curved" className="remix-icon" />,
    title: "User Management",
    navLink: "/userList",
    role: 'Admin',
  },
  // {
  //   id: "employeelist",
  //   icon: <BsListTask set="curved" className="remix-icon" />,
  //   title: "Employee list",
  //   navLink: "/employeelist",
  //   role: 'Admin',
  // },

  
  // {
  //   id: "all-reports",
  //   title: "Reports",
  //   icon: <Graph set="curved" className="remix-icon" />,
  //   children: [
  //     {
  //       id: "blank-page",
  //       title: "Blank Page",
  //       navLink: "/pages/blank-page",
  //     },
  //   ],
  // },
];

export default pages;
