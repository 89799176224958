import { lazy } from "react";

const PagesRoutes = [
  {
    path: "/login",
    component: lazy(() => import("../../pages/authentication/login")),
    layout: "FullLayout",
    pageType: "authentication",
  },
  {
    path: "/",
    exact: true,
    component: lazy(() => import("../../pages/dashboard")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/subjectlist",
    exact: true,
    component: lazy(() => import("../../pages/patient/index")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/FollowUpSubject",
    exact: true,
    component: lazy(() => import("../../pages/FollowUpSubject/index")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/addsubject",
    exact: true,
    component: lazy(() => import("../../pages/patient/addpatient")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/employeelist",
    exact: true,
    component: lazy(() => import("../../pages/empwisesubject")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/reports",
    exact: true,
    component: lazy(() => import("../../pages/reports")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/userList",
    exact: true,
    component: lazy(() => import("../../pages/UserManagement")),
    layout: "VerticalLayout",
    pageType: "private",
  },
  {
    path: "/excellreport",
    exact: true,
    component: lazy(() => import("../../pages/excelReport")),
    layout: "VerticalLayout",
    pageType: "private",
  },
];

export default PagesRoutes;
