export const version = `api/${process.env.REACT_APP_VERSION}/`;
export const loginUrl='/user/login';
export const getmenuUrl = version + 'Menu/getmenu';
//export const getmasterdataUrl = version + 'MasterData/GetMasterData';
export const getLanguageDataListUrl = version + 'MasterData/GetLanguageDataList';
export const getSubjectDataByEmployeeIdUrl = version + 'Patient/GetSubjectDataByEmployeeId';

export const saveDiabetesHistory = version + 'Patient/SaveDiabetesHistory';
export const saveVitalSignUrl = version + 'Patient/SaveVitalSign';
export const deleteDemographicsUrl = version + 'Patient/DeleteDemographics';
export const verifyPatientDetailsUrl = version + 'Patient/VerifyPatientDetails';
export const saveStatusDetailsUrl = version + 'Patient/SaveStatusDetails';


export const getDemographicDetailsBySubjectIdUrl = version + 'Patient/GetDemographicDetailsBySubjectId?SubjectId=';
export const getVitalSignBySubjectIdUrl = version + 'Patient/GetVitalSignBySubjectId?SubjectId=';
export const getLifeStyleFactorsBySubjectIdUrl = version + 'Patient/GetLifeStyleFactorsBySubjectId?SubjectId=';
export const getConcomitantMedicationsBySubjectIdNewUrl = version + 'Patient/GetConcomitantMedicationsBySubjectIdNew?SubjectId=';
//export const saveLifeStyleFactorsUrl = version + 'Patient/SaveLifeStyleFactors';
export const saveVitalParametersUrl = version + 'Patient/SaveVitalParameters';
export const getVitalParametersBySubjectIdUrls = version + 'Patient/GetVitalParametersBySubjectId?SubjectId=';
export const saveSignsAndSymptomsDuringPostureChangUrl = version + 'Patient/SaveSignsAndSymptomsDuringPostureChang';
export const getSignsAndSymptomsDuringPostureChangeBySubjectIdUrl = version + 'Patient/GetSignsAndSymptomsDuringPostureChangeBySubjectId?SubjectId=';
export const getCurrentMedicationsBySubjectIdUrls = version + 'Patient/GetCurrentMedicationsBySubjectId?SubjectId=';
export const saveConcomitantComorbidConditionsUrl = version + 'Patient/SaveConcomitantComorbidConditions';
export const getConcomitantComorbidConditionsBySubjectIdUrls = version + '/Patient/GetConcomitantComorbidConditionsBySubjectId?SubjectId=';
export const saveMedicalHistoryURL = version + 'Patient/SaveMedicalHistory';
export const saveInsulinTreatmentUrl = version + 'Patient/SaveInsulinTreatment';
export const saveInsulinInitiationReasonUrl = version + 'Patient/SaveInsulinInitiationReason';
export const getMedicalConditionBySubjectIdUrl = version + 'Patient/GetMedicalConditionBySubjectId?SubjectId=';
export const getInsulinInitiationReasonBySubjectIdUrl = version + 'Patient/GetInsulinInitiationReasonBySubjectId?SubjectId=';
export const getInsulinTreatmentBySubjectIdUrl = version + 'Patient/GetInsulinTreatmentBySubjectId?SubjectId=';
export const getMedicalComorbiditiesBySubjectIdUrl = version + 'Patient/GetMedicalHistoryBySubjectId?SubjectId=';
export const getDiabetesHistoryBySubjectIdUrl = version + 'Patient/GetDiabetesHistoryBySubjectId?SubjectId=';
export const saveMedicationDetailsUrl = version + 'Patient/SaveMedicationDetails';
export const getMedicationDetailsBySubjectIdUrl = version + 'Patient/GetMedicationDetailsBySubjectId?SubjectId=';
export const saveConcomitantMedicationsUrl = version + 'Patient/SaveConcomitantMedications';
export const saveConcomitantMedicationsNewUrl = version + 'Patient/SaveCurrentMedications';
export const getConcomitantMedicationsBySubjectIdUrl = version + 'Patient/GetConcomitantMedicationsBySubjectId?SubjectId=';
export const getConcomitantComorbidConditionsBySubjectId1Url = version + 'Patient/GetConcomitantComorbidConditionsBySubjectId?SubjectId=';
export const getLabInvestigationBySubjectIdUrl = version + 'Patient/GetLabInvestigationBySubjectId?SubjectId=';
export const saveLabInvestigationUrl = version + 'Patient/SaveLabInvestigation';

export const saveInvestigationsUrl = version + 'Patient/SaveInvestigations';
export const getInvestigationBySubjectIdUrl = version + 'Patient/GetInvestigationBySubjectId?SubjectId=';
export const getSubjectDataForAdminUrl = version + 'Patient/GetSubjectDataForAdmin';
export const getSubjectDataListforExcelUrl = version + 'Patient/GetSubjectDataListforExcel';
export const getSubjectDataforExcelUrl = version + 'Patient/GetSubjectDataforExcel';
export const prescriptionImageUploadUrl = version + 'Patient/PrescriptionImageUpload';



export const registerBulkUserUrl = version + 'Accounts/RegisterBulkUser';
export const registerUserUrl = version + 'Accounts/RegisterUser';
export const getUserListUrl = version + 'Users/GetUserList';
export const UploadFileUrl = version + 'Patient/UploadFile';


export const getSubjectDataforExcelnewUrl = version + 'ReportData/GetSubjectDataforExcelnew';



///blue index

//patient list

export const saveDemographicsUrl = '/patient/createpatientdemographics';
export const getmasterdataUrl = '/master-data/allmasterdata';
export const saveLifeStyleFactorsUrl = '/PatientMedicalHitory/SavePatientMedicalHIstory';
export const savepatientParamUrl = '/patient-parameters/SavePatientParameters';
export const parametersUrl = '/patient/parameters/';
export const GetPatietParametersByIdUrl = '/patient-parameters/GetPatietParametersById/';
export const GetAllPatientDetailsUrl = '/patient/GetAllPatientDetails';
export const GetsummaryUrl = '/patient/summary';
export const GetDemographicsbyIdUrl = '/patient/';
export const GetPatientMediacalHistoryByIdUrl = '/PatientMedicalHitory/GetPatientMediacalHistoryById/';
export const GetExportToExcelDataUrl = '/patient/report';
export const GetPatientAsMasterDataUrl = '/patient/GetPatientAsMasterData';
export const allusersUrl = '/user/allusers';
export const signupUrl = '/user/signup';
export const createbulklistusersUrl = '/user/createbulklistusers';







