import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import S3FileUpload from 'react-s3';
import AWS from "aws-sdk";
import { message } from "antd";

export function TextUpperCase(a) {
    return a.toUpperCase();
}

export function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
}

export function sessionStoragegetItem(Key) {
    return sessionStorage.getItem(Key);
}

export function StringtoIntConvert(string) {
    if (string != undefined && string != "")
        return parseInt(string, 10);
    else
        return 0
}

export function StringtoDecimalConvert(string) {
    if (string != undefined && string != "")
        return parseFloat(string, 10);
    else
        return 0
}


export function BmiCalculator(weight, height) {
    if (weight != undefined && weight != "" && height != undefined && height != "") {
        let bmi = (weight / height / height) * 10000;
        return bmi.toFixed(2);;
    } else
        return 0
}

export function CheckMaxLimit(value, MaxLimit) {
    if (value == undefined || value == "")
        return false
    else {
        if (value <= MaxLimit)
            return false
        else
            return true
    }
}
export function CheckMinMaxLimit(value, MinLimit, MaxLimit) {
    if (value > MinLimit && value <= MaxLimit)
        return false
    else
        return true

}

export function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

export function ExportToExcel(apidata, filename) {
    const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(apidata);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename + fileExtension);
}

export function OnlyUploadImageValidation(file) {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
        message.error('Image must smaller than 2MB!');
    }
    return isJpgOrPng && isLt2M;
}

AWS.config.update({
    accessKeyId: `${process.env.REACT_APP_S3_ACCESSKEY}`,
    secretAccessKey: `${process.env.REACT_APP_S3_SECRETKEY}`
  })
  const myBucket = new AWS.S3({
    params: { Bucket: `${process.env.REACT_APP_S3_BUCKET}` },
    region: `${process.env.REACT_APP_S3_REGION}`,
  
  })
  
  export const S3UploadImage = (file, fileName, foldername, Response) => {
    let Bucket = `${process.env.REACT_APP_S3_BUCKET}`;
    if (foldername != undefined && foldername != null && foldername != "") {
      Bucket = `${process.env.REACT_APP_S3_BUCKET}/${foldername}`;
    }
    const params = {
      // ACL: 'public-read',
      // Body: file,
      Body: file,
      Bucket: Bucket,
      Key: fileName
    };
  
    myBucket.putObject(params)
      .on('httpUploadProgress', (evt) => {
        // setProgress(Math.round((evt.loaded / evt.total) * 100))
      })
      .send((err, data) => {
        if (err) {
          Response(
            {
              IsError: true,
              ErrorMsg: "There is some problem, we are looking in to it, Please try later"
            }
          )
        }
        else {
          Response(
            {
              IsError: false,
              FileName: `${process.env.REACT_APP_S3_CLOUDFRONT_URL}${foldername}/${fileName}`,
              ErrorMsg: "Successfully Uploaded"
            }
          )
          // onSuccess(data.response, file);
        }
      })
  }

  
export const S3UploadBase64 = (file, fileName, foldername, Response) => {
  let Bucket = `${process.env.REACT_APP_S3_BUCKET}`;
  if (foldername != undefined && foldername != null && foldername != "") {
    Bucket = `${process.env.REACT_APP_S3_BUCKET}/${foldername}`;
  }
  const params = {
    // ACL: 'public-read',
    // Body: file,
    Body: new Buffer.from(file.replace(/^data:image\/\w+;base64,/, ""), 'base64'),
    Bucket: Bucket,
    Key: fileName
  };

  myBucket.putObject(params)
    .on('httpUploadProgress', (evt) => {
      // setProgress(Math.round((evt.loaded / evt.total) * 100))
    })
    .send((err, data) => {
      if (err) {
        Response(
          {
            IsError: true,
            ErrorMsg: "There is some problem, we are looking in to it, Please try later"
          }
        )
      }
      else {
        Response(
          {
            IsError: false,
            FileName: `${process.env.REACT_APP_S3_CLOUDFRONT_URL}/${foldername}/${fileName}`,
            ErrorMsg: "Successfully Uploaded"
          }
        )
        // onSuccess(data.response, file);
      }
    })
}

  