import { Button, Col, Layout, Row } from "antd";
import React, { createElement, useState } from "react";
import { RiMenuFoldLine, RiMenuUnfoldLine } from "react-icons/ri";
import { Link } from "react-router-dom";
import logoSmall from "../../../assets/images/logo/SmallLogo.png";
import MenuItem from "./item";
import MenuLogo from "./logo";
import MenuMobile from "./mobile";

const { Sider } = Layout;

const Sidebar = (props) => {
  const { visible, setVisible } = props;
  const [collapsed, setCollapsed] = useState(false);

  // Mobile Sidebar
  const onClose = () => {
    setVisible(false);
  };

  // Menu
  function toggle() {
    setCollapsed(!collapsed);
  }

  const trigger = createElement(collapsed ? RiMenuUnfoldLine : RiMenuFoldLine, {
    className: "trigger",
    onClick: toggle,
  });

  return (
    <Sider
      trigger={null}
      collapsible
      collapsed={collapsed}
      width={256}
      className="da-sidebar da-bg-color-black-0 da-bg-color-dark-100"
    >
      <Row gutter={[32,32]}
        className="da-mr-12 da-ml-24 da-mt-24"
        align="bottom"
        justify="center"
      >
        <Col span={16} className="da-text-center">{collapsed === false ? <MenuLogo onClose={onClose} /> : ""}</Col>

        <Col className="da-pr-0">
          <Button
            icon={trigger}
            type="text"
            className="da-float-right da-text-color-dark-0"
          ></Button>
        </Col>

        {collapsed !== false && (
          <Col  className="da-mt-8 smallLogo">
            <Link to="/" onClick={onClose}>
              <img className="da-logo" src={logoSmall} alt="logo" />
            </Link>
          </Col>
        )}
      </Row>

      <MenuItem onClose={onClose} />
      <MenuMobile onClose={onClose} visible={visible} />
    </Sider>
  );
};

export default Sidebar;
