import { Drawer } from "antd";
import React from "react";
import { RiCloseFill } from "react-icons/ri";
import MenuItem from "../item";
import MenuLogo from "../logo";

const MenuMobile = ({ onClose, visible }) => {
  return (
    <Drawer
      title={<MenuLogo onClose={onClose} />}
      className="da-mobile-sidebar"
      placement="left"
      closable={true}
      onClose={onClose}
      visible={visible}
      closeIcon={
        <RiCloseFill className="remix-icon da-text-color-black-80" size={24} />
      }
    >
      <MenuItem onClose={onClose} />
    </Drawer>
  );
};

export default MenuMobile;
