import React from 'react';
import { Col, Spin } from 'antd';

function Loader() {
    return (
        <Col style={{ textAlign: "center" }} className="da-mt-16" lg={24} span={30}>
            <Spin size="large" />
        </Col>
    );
}

export default Loader;
