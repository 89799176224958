import { Link } from "react-router-dom";
import Yoda from "../../../../assets/images/logo/logo.svg";
import ZomelisLogo from "../../../../assets/images/logo/Logo.png";


const MenuLogo = (props) => {
  return (
    <Link
      to="/"
      className="da-header-logo da-d-flex da-align-items-end da-ml-24"
      onClick={props.onClose}
    >
      <img className="da-logo" src={ZomelisLogo} alt="logo" />
      {/* <span className="h3 d-font-weight-800 da-text-color-primary-1 da-mb-6">
        .
      </span> */}

      {/* <span
        className="da-p1-body da-font-weight-500 da-text-color-black-40 da-mb-16 da-ml-4"
        style={{
          letterSpacing: -1.5,
        }}
      >
        v. 1.0
      </span> */}
    </Link>
  );
};

export default MenuLogo;
