import { Button, Col, Layout, Row } from "antd";
import React from "react";
import { RiMenuFill } from "react-icons/ri";
import HeaderNotifications from "./HeaderNotifications";
import HeaderUser from "./HeaderUser";

const { Header } = Layout;

const MenuHeader = ({ setVisible }) => {
  return (
    <Header>
      <Row justify="center" className="da-w-100">
        <Col span={24}>
          <Row
            className="da-w-100 da-position-relative"
            align="middle"
            justify="space-between"
          >
            <Col className="da-mobile-sidebar-button da-mr-24">
              <Button
                className="da-mobile-sidebar-button"
                type="text"
                onClick={() => {
                  setVisible(true);
                }}
                icon={
                  <RiMenuFill
                    size={24}
                    className="remix-icon da-text-color-black-80 da-text-color-dark-30"
                  />
                }
              />
            </Col>
            <Col></Col>
            <Col>
              <Row align="middle">
                {/* <HeaderNotifications /> */}
                <HeaderUser />
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </Header>
  );
};

export default MenuHeader;
