import { Badge, Button, Col, Divider, Dropdown, Row } from "antd";
import React from "react";
import { Notification } from "react-iconly";

const HeaderNotifications = () => {
  const notificationMenu = (
    <div className="da-py-16 da-bg-color-black-0 da-bg-color-dark-100 da-border-color-black-40 da-border-color-dark-80 da-border-radius da-border-1">
      <div className="da-px-16">
        <Row align="middle">
          <Col>
            <span className="da-d-block da-w-100 da-mb-4 da-badge-text da-font-weight-500 da-text-color-black-100 da-text-color-dark-10">
              Notification Title
            </span>

            <span className="da-d-block da-input-description da-font-weight-500 da-text-color-black-80 da-text-color-dark-40">
              Notification Subtitle.
            </span>
          </Col>
        </Row>
      </div>

      <Divider className="da-my-16 da-mx-0 da-bg-color-black-40 da-bg-color-dark-80" />

      <div className="da-px-16">
        <Button type="primary" block>
          Mark all read
        </Button>
      </div>
    </div>
  );

  return (
    <Col className="da-d-flex-center da-mr-sm-12 da-mr-16">
      <Button
        type="text"
        icon={
          <Dropdown overlay={notificationMenu} placement="bottomRight">
            <div className="da-position-relative">
              <div
                className="da-position-absolute"
                style={{ right: -5, top: -5 }}
              >
                <Badge dot status="processing" />
              </div>

              <Notification set="curved" className="da-text-color-black-60" />
            </div>
          </Dropdown>
        }
      />
    </Col>
  );
};

export default HeaderNotifications;
